/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Content {
  display: flex;
  max-width: 1200px;
  margin: auto;
}

.Content-left {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.Content-right {
  display: flex;
  flex-direction: column;
  background-color: #F8F8FA;
  /* background: url(../public/Notebook.png); */
  background-size: contain;
  width: 35%;
  padding: 30px 24px 24px 3%;
  height: 95vh;
  overflow:auto;
  font-size: 1em;
  line-height: 1.8;
}

.Content-right a {
  text-decoration: none;
  font-weight: 700;
  color: #1563CC;
}

.avatarBox img { border-radius: 15vh; }

.avatarBox span {
  position: absolute; 
  left: 50%;
  bottom: -24px; 
  transform: translateX(-50%); 
  background-color: #000; 
  color: #fff;
  font-weight: bold;
  padding: 8px; 
  border-radius: 6px;
}

@keyframes Wave {
  0% { transform: rotateZ(-30deg); }
  15% { transform: rotateZ(0deg); }
  30% { transform: rotateZ(-30deg); }
  45% { transform: rotateZ(0deg); }
  60% { transform: rotateZ(-30deg); }
  100% { transform: rotateZ(-30deg); }
}

.introMessage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #C8C8CA;
  font-weight: bold;
  align-items: center;

  img { width: 15%; animation: Wave 2s infinite; }
  p { font-size: 32px; }
  span { font-size: 24px; }
}

.message {
  padding: 16px;
  border-radius: 16px;
  margin-block: 8px;
  width: fit-content;
  line-height: 1.8;
}

.messageCoach {
  font-weight: bold;
  align-self: flex-start;
  text-align: left;
}

.messageCoachee {
  background-color: #F8F8FA;
  align-self: flex-end;
  text-align: left;
  max-width: 80%;
}

.coachlink {
			display: flex; align-items: center; justify-content: center;
			a {
				text-decoration: none; color: #0051ad; font-weight: bold; 
				border: 2px solid #fff;
			}
			a:hover {
				border-bottom: 2px solid #0051ad;
			}
			a::after {
	            content: " \2192"; 
	            margin-left: 5px; 
	        }
		}
		.box {
			display: flex; flex-direction: column;
			width: 480px; padding: 0 16px 8px 16px;
			margin: 40px auto; border: 1px solid #D9D9DA; border-radius: 16px;
		}
		.coachee {
			display: flex; height: 80px;
		}
		.coachee:not(:last-child) { border-bottom: 1px solid #D9D9DA; }
		.nametag {
			width: 70%; display: flex; flex-direction: row; align-items: center;

			p {
				margin-left: 24px;

			}
		}
		.nameicon {
			width: 48px; height: 48px; display: flex; 
			align-items: center; justify-content: center;
			font-weight: bold; border-radius: 50%;
		}
		.color1 { color: #006ead; background-color: #006ead22; }
		.color2 { color: #bd4a08; background-color: #bd4a0822; }
		.color3 { color: #5608bd; background-color: #5608bd22; }
		.color4 { color: #017d5c; background-color: #017d5c22; }
		.color5 { color: #bf9c00; background-color: #bf9c0022; }

.view-selector {
  display: flex;
  margin: auto;
}

.custom-tab {
  color: #848488 !important;
  border-radius: 50vh !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-family: 'Plus Jakarta Sans, sans-serif';
  background-color: transparent !important;
}

.custom-tab-selected {
  color: #000000 !important;
  background-color: #e5e5e8 !important;
  font-weight: 700;
}


.hide-content {
  display: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.display-linebreak {
  white-space: pre-line;
}

/* Coachee page CSS */

.coachlink {
  display: flex; align-items: center; justify-content: center;
  a {
    text-decoration: none; color: #0051ad; font-weight: bold; 
    border: 2px solid #fff;
  }
  a:hover {
    border-bottom: 2px solid #0051ad;
  }
  a::after {
          content: " \2192"; 
          margin-left: 5px; 
      }
}
.coacheebox {
  display: flex; flex-direction: column;
  width: 480px; padding: 0 16px 8px 16px;
  margin: 40px auto; border: 1px solid #D9D9DA; border-radius: 16px;
}
.coachee {
  display: flex; height: 80px;
}
.coachee:not(:last-child) { border-bottom: 1px solid #D9D9DA; }
.nametag {
  width: 70%; display: flex; flex-direction: row; align-items: center;

  p {
    margin-left: 24px;

  }
}
.nameicon {
  width: 48px; height: 48px; display: flex; 
  align-items: center; justify-content: center;
  font-weight: bold; border-radius: 50%;
}
.color1 { color: #006ead; background-color: #006ead22; }
.color2 { color: #bd4a08; background-color: #bd4a0822; }
.color3 { color: #5608bd; background-color: #5608bd22; }
.color4 { color: #017d5c; background-color: #017d5c22; }
.color5 { color: #bf9c00; background-color: #bf9c0022; }