/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: #000000;
  color: #c3c3c6;
  padding: 16px 16px;
  border-radius: 8px;
}

ul, ol {
  padding-inline-start: 0;
}

li {
  line-height: 1.8;
  padding-left: 16px;
}

.bar-container {
  display: flex;
  float: right;
  margin-left: 10px;
  justify-content: space-between;
  width: 30px;
  height: 80px;
}

.bar {
  width: 20%;
  height: 0;
  background-color: #759DEC;
  border-radius: 5px;
}

.middle {
  height: 0;
}

.hidden {
  visibility: hidden;
}

.shadow-box {
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7), 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4vh;
}

.current-text {
  background-color: #F8F8FA;
  border-radius: 8px;
}

blockquote {
  background-color: #E5E5E8;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 16px;
  border-left: 8px solid #c3c3c6;
  border-radius: 8px;
}

.shadow-back-button {
  box-shadow: inset 4px 4px 8px 1px rgba(0,0,0,0.2), inset -4px -4px 8px 1px rgba(255,255,255,0.7);
  width: 6vh;
  height: 6vh;
  display: flex;
  align-items: 'center';
  border-radius: 50%;
}

.shadow-update-button {
    box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.7), 10px 10px 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 3vh !important;
    font-family: "Google Sans", sans-serif !important;
    font-size: 2vh !important;
    text-transform: none !important;
    width: 17vh;
    height: 5.5vh;
    background-color: #FFFFFF !important;
}

.tool-bar {
  justifyContent: 'space-between';
  padding: 2vh 0 2vh 0 !important;
  width: 100%;
}